import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHolidays } from './holidays.actions';
import { paginationSelector } from './holidays.selector';

function useHoliday() {
  const dispatch = useDispatch();

  const pagination = useSelector(paginationSelector);
  useEffect(() => {
    dispatch(fetchHolidays(pagination));
  }, []); // fetch holidays on component mount, todo: add pagination
}

export default useHoliday;
