import HolidaysFeed from 'content/features/holidays/componentes/HolidaysFeed';
import useHoliday from 'content/features/holidays/holidays.hooks';
import React from 'react';

function Holidays() {
  useHoliday();
  return <HolidaysFeed />;
}

export default Holidays;
