import React from 'react';
import HolidaysTitle from './HolidaysTitle';
import HolidaysTable from './HolidaysTable';

function HolidaysFeed() {
  return (
    <>
      <HolidaysTitle />
      {/* <HolidaysSearch /> */}
      <HolidaysTable />
    </>
  );
}

export default HolidaysFeed;
