/* Redux Saga */
import { all } from 'redux-saga/effects';

/* Project */
import AppSaga from 'content/app.saga';
import { CounterSaga } from 'content/features/counter';
import { UserTasksSaga } from 'content/features/tasks';
import { RecoverySaga, LoginSaga, RegisterSaga } from 'content/features/authentication';
import { BulkLoadSaga } from 'content/features/bull-load';
import { EmployeeSaga } from 'content/features/list-payroll';
import { DetailsPayrollSaga } from 'content/features/details-payroll';
import { DiscountEmployeeSaga } from 'content/features/details-discounts';
import { DiscountsSaga } from 'content/features/summary-discounts';
import { UserSaga } from 'content/features/user';
import { ChangePasswordSaga } from 'content/features/changePassword';
import { ItemTypeSaga } from 'content/features/item-type';
import { HolidaySaga } from 'content/features/holidays';

export default function* MainSaga() {
  yield all([
    LoginSaga(),
    CounterSaga(),
    AppSaga(),
    UserTasksSaga(),
    BulkLoadSaga(),
    DiscountsSaga(),
    DiscountEmployeeSaga(),
    RecoverySaga(),
    RegisterSaga(),
    EmployeeSaga(),
    DetailsPayrollSaga(),
    UserSaga(),
    ChangePasswordSaga(),
    ItemTypeSaga(),
    HolidaySaga(),
  ]);
}
