import { fetchHolidays, fetchHolidaysFail, fetchHolidaysSuccess } from './holidays.actions';

const { createReducer } = require('@reduxjs/toolkit');

const initialState = {
  pagination: {
    currentPage: 1,
    pageSize: 8,
    totalPages: 1,
    searchValue: '',
  },
  data: [],
  isLoading: false,
};

const HolidaysReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchHolidays, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchHolidaysSuccess, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.pagination = {
        ...state.pagination,
        ...action.payload.pagination,
      };
    })
    .addCase(fetchHolidaysFail, (state) => {
      state.isLoading = false;
    });
});

export default HolidaysReducer;
