import { createAction } from '@reduxjs/toolkit';
import HolidasTypes from './holidays.types';

export const fetchHolidays = createAction(HolidasTypes.FETCH_HOLIDAYS);
export const fetchHolidaysSuccess = createAction(HolidasTypes.FETCH_HOLIDAYS_SUCCESS);
export const fetchHolidaysFail = createAction(HolidasTypes.FETCH_HOLIDAYS_FAIL);

const HolidaysActions = {
  fetchHolidays,
};
export default HolidaysActions;
