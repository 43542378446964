import CustomTable from 'content/features/other/components/CustomTable';
import React from 'react';

function HolidaysTable() {
  const mockData = [
    {
      id: 1,
      date: '2021-01-01',
      description: 'Ano Novo',
    },
    {
      id: 2,
      date: '2021-02-15',
      description: 'Carnaval',
    },
    {
      id: 3,
      date: '2021-02-16',
      description: 'Carnaval',
    },
    {
      id: 4,
      date: '2021-04-02',
      description: 'Sexta-feira Santa',
    },
    {
      id: 5,
      date: '2021-04-21',
      description: 'Tiradentes',
    },
    {
      id: 6,
      date: '2021-05-01',
      description: 'Dia do Trabalho',
    },
    {
      id: 7,
      date: '2021-09-07',
      description: 'Independência do Brasil',
    },
    {
      id: 8,
      date: '2021-10-12',
      description: 'Nossa Senhora Aparecida',
    },
    {
      id: 9,
      date: '2021-11-02',
      description: 'Finados',
    },
    {
      id: 10,
      date: '2021-11-15',
      description: 'Proclamação da República',
    },
    {
      id: 11,
      date: '2021-12-25',
      description: 'Natal',
    },
  ];
  const data = {
    headers: [
      { name: 'Fecha', itemKey: 'date' },
      { name: 'Descripción', itemKey: 'description' },
    ],
    items: mockData,
  };
  return <CustomTable color="primary" data={data} />;
}

export default HolidaysTable;
