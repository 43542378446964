import React from 'react';
import { Grid } from '@mui/material';
import PageTitle from 'content/features/other/components/PageTitle';

function HolidaysTitle() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <PageTitle title="Feriados" />
      </Grid>
    </Grid>
  );
}

export default HolidaysTitle;
